import { Col } from "react-bootstrap";

export const CardProj = ({ title, description, imgUrl }) => {
  return (

   <Col>
      <div className="grid-container">
        <img src={imgUrl} alt="projects" />
        <div>
        {/* <h4>{title}</h4>
        <span>{description}</span> */}
        </div>
      </div>
    </Col>

   )
}




