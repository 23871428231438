import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { CardProj } from "./CardProj";
import projImg1 from "../assets/img/theshop.png";
import projImg2 from "../assets/img/590X300.png";
import projImg3 from "../assets/img/Prev.png";
import projImg4 from "../assets/img/school.jpg";
import projImg5 from "../assets/img/vshow.png";
import projImg6 from "../assets/img/saas.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Business Startup",
      description: "E-Commerce Platform with AI Recommendations",
      imgUrl: projImg1,
    },
    {
      title: "Business Startup",
      description: "Health and Fitness App with Wearable Integration",
      imgUrl: projImg2,
    },
    {
      title: "Business Startup",
      description: "Real Estate Management System",
      imgUrl: projImg3,
    },
    {
      title: "Business Startup",
      description: "School Management System",
      imgUrl: projImg4,
    },
    {
      title: "Business Startup",
      description: "Virtual Event and Conference Platform",
      imgUrl: projImg5,
    },
    {
      title: "Business Startup",
      description: "SaaS Project Management Tool",
      imgUrl: projImg6,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>At Pitrix Technology INC, our portfolio speaks volumes of our prowess. From developing comprehensive school management systems to empowering businesses with efficient management solutions, we've left our mark. Our expertise extends to crafting agency websites that captivate, shipping and logistics platforms that streamline operations, and seamless e-commerce experiences that drive growth. We've also curated engaging blog platforms, fortified bank and financial websites, and versatile accounting solutions. With an unwavering commitment to excellence, we're continuously working on projects that redefine possibilities, ensuring every venture is a testament to our dedication to innovation.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                        {
                          projects.map((project, index) => {
                            return (
                              <CardProj
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>Versatile projects span innovation, like school and business management systems, agency, shipping, ecommerce, blog, finance websites. Ongoing endeavors redefine possibilities.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Diverse ventures showcase innovation: school & business management systems, agency, shipping, ecommerce, blog, finance websites. Ongoing projects continue to redefine possibilities.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
